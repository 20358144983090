import "../FeaturedListings/FeaturedListings.css";

import {useEffect} from "react";

import {useLocation} from "react-router-dom";

import Navbar from "../../components/Navbar/Navbar";

import MenuOverlay from "../../pages/MenuOverlay/MenuOverlay";

export default function RentMore()
{

    const location = useLocation();
    const rent = location.state;

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <>
        <Navbar />
        <section className="featuredListMore">
            <section className="featuredListMore-left">
                <img src={rent.coverImg} alt="house" className="featuredListMore-left-img" />
            </section>
            
            <section className="featuredListMore-right">
                <div className="featuredListMore-right-price">${rent.price}/mo</div>
                <div className="featuredListMore-right-address">{rent.address}</div>
                <div className="featuredListMore-bed-bath-sqft-type">
                    <div className="featuredListMore-beds">{rent.beds} beds • </div>
                    <div className="featuredListMore-baths">{rent.baths} baths • </div>
                    <div className="featuredListMore-sqft">{rent.sqft} sq ft • </div>
                    <div className="featuredListMore-type">{rent.type}</div>
                </div>
                {/* <div className="featuredListMore-mls">MLS # {rent.mls}</div> */}
                <div className="featuredListMore-desc">{rent.desc}</div>
            </section>
        </section>
        <MenuOverlay />
        </>
    )
}