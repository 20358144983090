import './Rent.css';

import { useEffect } from "react";

import {Link} from "react-router-dom";

// import { getRent } from "../../utilities/rent-api";

// import InquiryForm from '../../components/InquiryForm/InquiryForm';

import Navbar from '../../components/Navbar/Navbar';

import MenuOverlay from '../MenuOverlay/MenuOverlay';

// import HomesForRentForm from "./HomesForRentForm";

// import RentList from "../../pages/Rent/RentList";

export default function Rent()
{
    const renting1 = require("../../data/rental1.webp")
    const renting2 = require("../../data/rental2.webp")


    useEffect(() => {
        window.scrollTo(0, 0);
    }
    , [])

    // Updates State when Featured Listings are editted or deleted
    // useEffect(() => {
    //     const fetchUpdatedListing = async() => {
    //         try
    //         {
    //             const updatedListings = await getRent();
    //             setRentItems( updatedListings )
    //         }
    //         catch(err)
    //         {
    //             console.log("Error fetching updated rent");
    //         }
    //     }
    //     fetchUpdatedListing();
    // }, [])

    return (
        <>
        <Navbar />
        <div className='rent-main'>
            <h1 className='rent-title'>Rentals</h1>

            <section className="featuredList-listing">
                <div className="featuredList-mainImage">
                    <img src={renting1} alt="house" className="featuredList-mainImage-img" />
                </div>
                <div className="featuredList-value-price">$1900/mo</div>
                <div className="featuredList-address">5455 Heath Creek Dr, Wrightwood, CA 92397</div>
                <div className="featuredList-brief-desc">
                    <div className="featuredList-value-bed">2 beds•</div>
                    <div className="featuredList-value-baths">1 bath•</div>
                    <div className="featuredList-value-sqfootage">1189 sq ft•</div>
                    <div className="featuredList-value-type">Single Family Home</div>
                </div>
                {/* <div className="featuredList-mls">MLS # { rent.mls }</div> */}

                {/* {rent.address ? <> */}
                <Link to={"/rentDetails?rentId=666b6f44a680627585417624"} className="featuredList-edit-link" state={{
                    _id: "666b6f44a680627585417624",
                    address: '5455 Heath Creek Dr, Wrightwood, CA 92397',
                    beds: '2',
                    baths: '1',
                    type: 'Single Family Home',
                    price: '1900',
                    sqft: '1189',
                    coverImg: renting1,
                    desc: 'Charming spacious mountain home features 2 bedrooms, 1 full bath, detached over-sized single car garage located on a large level lot. Includes chimney in great room and an open floor plan. Easy access driveway - important during the snow season. views and trees.'
                }} >See More</Link>
                {/* </>: null} */}
            </section>


            <section className="featuredList-listing">
                <div className="featuredList-mainImage">
                    <img src={renting2} alt="house" className="featuredList-mainImage-img" />
                </div>
                <div className="featuredList-value-price">$1940/mo</div>
                <div className="featuredList-address">1470 Laura St, Wrightwood, CA 92397</div>
                <div className="featuredList-brief-desc">
                    <div className="featuredList-value-bed">2 beds•</div>
                    <div className="featuredList-value-baths">2 baths•</div>
                    <div className="featuredList-value-sqfootage">1211 sq ft•</div>
                    <div className="featuredList-value-type">Single Family Home</div>
                </div>
                {/* <div className="featuredList-mls">MLS # { rent.mls }</div> */}

                {/* {rent.address ? <> */}
                <Link to={"/rentDetails?rentId=666b6f47a680627585417625"} className="featuredList-edit-link" state={{
                    _id: "666b6f47a680627585417625",
                    address: '1470 Laura St, Wrightwood, CA 92397',
                    beds: '2',
                    baths: '2',
                    type: 'Single Family Home',
                    price: '1940',
                    sqft: '1211',
                    coverImg: renting2,
                    desc: "Classic Wrightwood cabin with large great room features beamed, cathedral ceilings and a fireplace. Both bedrooms are spacious. New vinyl flooring in great room. Above average size kitchen. Full bathroom and a half bath. Located on a corner lot. Showing schedule subject to tenant's availability."
                }} >See More</Link>
                {/* </>: null} */}
            </section>





            {/* <InquiryForm /> */}
            {/* {rentItems.map(rent=>(
                <RentList key={ rent._id } rent={rent} />
            ))} */}
            {/* {user ?
            <HomesForRentForm rentItems={rentItems} setRentItems={setRentItems} />
            : null} */}
        </div>
        <MenuOverlay />
        </>
    )
}