import './Home.css';

import About from '../About/About';

import ContactUs from '../ContactUs/ContactUs';

import Navbar from '../../components/Navbar/Navbar';

import FeaturedListings from '../FeaturedListings/FeaturedListings';

import AddFeaturedListingForm from '../AddFeaturedListingForm/AddFeaturedListingForm';

import MenuOverlay from '../MenuOverlay/MenuOverlay';

import { getFeaturedListings } from "../../utilities/featuredListings-api";

//buttons for the home page
// import { useState } from "react-router-dom";

//scroll library
import { Link as LinkScroller } from "react-scroll";
import { useEffect, useState } from 'react';

// const locationDay = require("../../data/location-day-3 copy.jpeg");

export default function Home() {
    // const [newListingAdded, setNewListingAdded] = useState(false);
    // // Updates State when Featured Listings are editted or deleted
    // useEffect(() => {
    //     const fetchUpdatedListing = async() => {
    //         try
    //         {
    //             const updatedListings = await getFeaturedListings();
    //             setFeaturedListingItems( updatedListings )
    //         }
    //         catch(err)
    //         {
    //             console.log("Error fetching updated Listing");
    //         }
    //     }
    //     fetchUpdatedListing();
    // }, [newListingAdded])

    return (
        <>
        <Navbar />
        <div id="home" className="Home">
            <section className="home-main">
                <div className='home-main-text'>
                    {/* <h1 className="home-main-title">Settle into your Perfect Home with Park Place Realty</h1> */}
                    {/* <div className='home-main-search'>
                        <input className='home-main-input' type="text" placeholder="City, Address, Zip" />
                        <button className='home-main-button'>
                            <SearchIcon className='search-icon' />
                            <div className='home-main-button-text'>Search</div>
                        </button>
                    </div> */}
                </div>
            </section>
        </div>
        <section className='home-contact'>
                <div className='home-contact-text'>
                    Established in January 1990, Park Place Realty is Wrightwood's longest serving 
                    real estate company selling the most basic cabin to million dollar homes. 
                    We also specialize in managing long term rentals.
                </div>
                <div className='home-contact-details'>
                    <div>Reach us at</div>
                    <div>(760)249-1001</div>
                    <div>Fax: (760)249-6455</div>
                    <a href="mailto:daniel@parkplacerealty.com">daniel@parkplacerealty.com</a>                
                </div>
        </section>
        <FeaturedListings className="featuredListings" />
        {/* <div className='editFeaturedListingForm'>
        {user ?
         <AddFeaturedListingForm featuredListingItems={featuredListingItems} setFeaturedListingItems={setFeaturedListingItems} setNewListingAdded={setNewListingAdded}/>
            :null}
        </div> */}
        {/* <div id="contactUs">
        <ContactUs /></div> */}
        <MenuOverlay />
        <LinkScroller to="home" spy={true} smooth={true} offset={-70} duration={1000} className="home-button">Scroll To Top</LinkScroller>
        </>
    )
}