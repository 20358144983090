import './App.css';



import { useState, useEffect } from 'react';

//Routes: Will match a set of child routes from the current location(client-side routing)
//Route: renders the element assigned to its element prop when its path prop matches the current URL
//Navigate: changes current location when it is rendered
import { Routes, Route, Navigate } from 'react-router-dom';

// database API logic
// import { getUser } from "../../utilities/users-services";
// // featured Listings
// import { getFeaturedListings } from "../../utilities/featuredListings-api";
// // recent Sales
// import { getRecentSales } from "../../utilities/recentSales-api";
// // about Agents
// import {getAgents} from "../../utilities/about-api";
// // for sale
// import {getHomes} from "../../utilities/buy-api";
// // for rent
// import {getRent} from "../../utilities/rent-api"

//Pages

import Home from "../Home/Home";

import AuthPage from '../AuthPage/AuthPage';

import About from "../About/About";

import AboutAgentMore from "../../components/AboutAgentMore/AboutAgentMore";

import List from "../List/List";

import FeaturedListings from "../FeaturedListings/FeaturedListings";

import FeaturedListMore from "../../components/FeaturedListMore/FeaturedListMore";

import EditFeaturedListingForm from "../AddFeaturedListingForm/AddFeaturedListingForm";

import CalculateMortgage from "../CalculateMortgage/CalculateMortgage";

import HomeSearch from '../HomeSearch/HomeSearch';

import HomesForSale from "../HomesForSale/HomesForSale";

import Sell from "../Sell/Sell";

import GetHomeValue from "../GetHomeValue/GetHomeValue";

import RecentSales from "../RecentSales/RecentSales";

import EditRecentSaleForm from '../EditRecentSaleForm/EditRecentSaleForm';

import ContactUs from "../ContactUs/ContactUs";

import Rent from '../Rent/Rent';

import RentMore from "../../pages/Rent/RentMore";

import FeaturedRentals from '../FeaturedRentals/FeaturedRentals';

import VacationRentals from '../VacationRentals/VacationRentals';

import RecentRentals from '../RecentRentals/RecentRentals';

// const location_day_3 = require("../../data/location-day-3.jpeg");

export default function App() {

  // useState() is a Hook that simplifies the process of editting and calling state. 

  //retrieve current user credentials which is used for authorization and passing down of state
//   const [user, setUser] = useState( getUser() );

//   // ******************** HamburgerMenu ********************
//   const [isOpen, setIsOpen] = useState( true );

//   // ******************** Featured Listings ********************
//   const [featuredListingItems, setFeaturedListingItems] = useState([]);
//   // ensures data is re-rendered properly after post req
//   useEffect(function()
//   {
//     async function getFeatListings()
//     {
//       const featListings = await getFeaturedListings();
//       setFeaturedListingItems(featListings);
//     }
//     getFeatListings();
//   }, [])

//   // ******************** Featured Listings ********************
//   const [rentItems, setRentItems] = useState([]);
//   // ensures data is re-rendered properly after post req
//   useEffect(function()
//   {
//     async function getRentListings()
//     {
//       const rentListings = await getRent();
//       setRentItems(rentListings);
//     }
//     getRentListings();
//   }, [])

//   // ******************** Current Listings ********************
//   const [currentListingItems, setCurrentListingItems] = useState([]);
//   useEffect(function()
// {
//   async function getCurrentListings()
//   {
//     try
//     {
//       const currentListings = await getHomes();
//       setCurrentListingItems(currentListings)
//     }
//     catch
//     {
//       console.log("Error fetching Current Listings")
//     }
//   }
//   getCurrentListings();
// }
// , [])


//   // ******************** Recent Sales ********************
//   const [recentSalesItems, setRecentSalesItems] = useState([]);
//   useEffect(function()
// {
//   async function getRecSales()
//   {
//     try
//     {
//       const recSales = await getRecentSales();
//       setRecentSalesItems(recSales)
//     }
//     catch
//     {
//       console.log("Error fetching Recent Sales")
//     }
//   }
//   getRecSales();
// }, [])

// // ******************** About Agents ********************
// const [aboutAgents, setAboutAgents] = useState([]);
// useEffect(function()
// {
//   async function getAboutAgents()
//   {
//     try
//     {
//       const agents = await getAgents();
//       setAboutAgents(agents)
//     }
//     catch
//     {
//       console.log("Error fetching Agents' About pages")
//     }
//   }
//   getAboutAgents();
// }, [])

  // ******************** Featured Listing ********************
//   const [featuredListingItem, setFeaturedListingItem] = useState([]);
//   // ensures data is re-rendered properly after post req
//   useEffect(function()
//   {
//   async function getFeatListing()
//   {
//     const featListing = await getFeaturedListing( listingId );
//     setFeaturedListingItem( featListing )
//   }
//   getFeatListing();
// }, [])


  return (
    <main className="App">
     { 
      <>
      {/* <img className='location-day-3' src={ location_day_3 } alt="location day" width="100%" height="auto" /> */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/aboutAgentMore" element={<AboutAgentMore />} />
          <Route path="/list" element={<List />} />
          <Route path="/featured_listings" element={<FeaturedListings />} />
          <Route path="/featured_listingsDetails" element={<FeaturedListMore />} />
          <Route path="/rentDetails" element={<RentMore />} />
          <Route path="/editListing" element={ <EditFeaturedListingForm /> } />
          <Route path="/home_search" element={<HomeSearch />} />
          <Route path="/calculate_mortgage" element={<CalculateMortgage />} />
          <Route path="/buy" element={<HomesForSale  />} />
          <Route path="/sell" element={ <Sell /> } />
          <Route path="/get_home_value" element ={ <GetHomeValue /> } />
          <Route path="/recent_sales" element={ <RecentSales  /> } />
          <Route path="/editSale" element={ <EditRecentSaleForm /> } />
          <Route path="/contact" element={ <ContactUs />} />
          <Route path="/rent" element={ <Rent /> } />
          {/* <Route path="/featuredRentals" element={ <FeaturedRentals /> } />
          <Route path="/vacationRentals" element={ <VacationRentals /> } />
          <Route path="/recentRentals" element={ <RecentRentals /> } /> */}
          {/* <Route path="/signup" element={<AuthPage user={ user } setUser={ setUser } />} /> */}
          <Route path="/*" element={<Navigate to="/home" />} />
        </Routes>
      </>
      
    }
    </main>
  );
}
