import './HomesForSale.css';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// import InquiryForm from "../../components/InquiryForm/InquiryForm";

import Navbar from "../../components/Navbar/Navbar";

import MenuOverlay from '../MenuOverlay/MenuOverlay';

const listing1 = require("../../data/listing1.jpeg");
const listing2 = require("../../data/listing2.jpeg");
const listing3 = require("../../data/listing3.jpeg");

// import HomesForSaleForm from './HomesForSaleForm';

// import FeaturedList from "../../components/FeaturedList/FeaturedList";

// import { getHomes } from "../../utilities/buy-api";

export default function HomesForSale() {

    // const [newListAdded, setNewListAdded] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
    }
    , [])

    // useEffect(() => {
    //     const fetchUpdatedListing = async() => {
    //         try
    //         {
    //             const updatedListings = await getHomes();
    //             setCurrentListingItems( updatedListings )
    //         }
    //         catch(err)
    //         {
    //             console.log("Error fetching updated homes");
    //         }
    //     }
    //     fetchUpdatedListing();
    // }
    // , [newListAdded])

    return (
        <>
        <Navbar />
        <div className='homesForSale-main'>
            <h1 className='homesforSale-title'>Homes For Sale</h1>
            {/* <InquiryForm /> */}


            <div className='featuredlistings-container'>
            <section className="featuredList-listing">
                <div className="featuredList-mainImage">
                    <img src={listing1} alt="house" className="featuredList-mainImage-img" />
                </div>
                <div className="featuredList-value-price">$478000</div>
                <div className="featuredList-address">6226 Conifer Drive Wrightwood, CA 92397</div>
                <div className="featuredList-brief-desc">
                    <div className="featuredList-value-bed">3 beds•</div>
                    <div className="featuredList-value-baths">2 baths•</div>
                    <div className="featuredList-value-sqfootage">1144 sq ft•</div>
                    <div className="featuredList-value-type">Single Family Residence</div>
                </div>
                <div className="featuredList-mls">MLS # 24100836</div>

                {/* {list.address ? <> */}
                <Link to={"/featured_listingsDetails?listingId=666b6736b8f9132b254f056d"} state={{
                    _id: "666b6736b8f9132b254f056d",
                    address: "6226 Conifer Drive Wrightwood, CA 92397",
                    beds: 3,
                    baths: 2,
                    type: "Single Family Residence",
                    mls: 24100836,
                    price: 478000,
                    sqft: 1144,
                    coverImg: listing1,
                    desc: "LOG HOME IN THE MOUNTAINS! Get away with family and friends in this cozy 1,200 square foot cabin built in 1956 nestled in the evergreens of Wrightwood, CA. Immaculate, charming and turnkey, this mountain home includes 3 bedrooms, 1.75 bathrooms and features a knotty pine interior, beamed ceilings, a great room with a fireplace, mid-century modern style kitchen, updated flooring throughout, front and rear decks, a fully fenced spacious backyard with a shed – plumbed and secure all on a large 7,800 square foot lot. Laundry hookups in shed. Additional highlights include ample parking with RV space and over-all easy access, particularly valued in the winter season. Retro furnishings are negotiable. Note: downstairs bedroom may also be considered a bonus area for an office, library or crafts room."
                }} className="featuredList-edit-link">See More</Link>

                



                {/* </>: null} */}
            </section>
        </div>

        {/* <div className='featuredlistings-main-heading'>Featured Listings</div> */}
        {/* <FeaturedListingForm featuredListingItems={ featuredListingItems } setFeaturedListingItems={ setFeaturedListingItems } /> */}
        <div className='featuredlistings-container'>
            <section className="featuredList-listing">
                <div className="featuredList-mainImage">
                    <img src={listing2} alt="house" className="featuredList-mainImage-img" />
                </div>
                <div className="featuredList-value-price">$539800</div>
                <div className="featuredList-address">1686 Blackbird Road Wrightwood, CA 92397</div>
                <div className="featuredList-brief-desc">
                    <div className="featuredList-value-bed">3 beds•</div>
                    <div className="featuredList-value-baths">2 baths•</div>
                    <div className="featuredList-value-sqfootage">1900 sq ft•</div>
                    <div className="featuredList-value-type">Single Family Residence</div>
                </div>
                <div className="featuredList-mls">MLS # 24062943</div>

                {/* {list.address ? <> */}
                <Link to={"/featured_listingsDetails?listingId=666b68dcb8f9132b254f05c8"} state={{
                    _id: "666b68dcb8f9132b254f05c8",
                    address: "1686 Blackbird Road Wrightwood, CA 92397",
                    beds: 3,
                    baths: 2,
                    type: "Single Family Residence",
                    mls: 24062943,
                    price: 539800,
                    sqft: 1900,
                    coverImg: listing2,
                    desc: "NEWLY UPDATED KITCHEN! Privacy and Seclusion are the watch words for this property. This Home sits on one of the Best Locations in Wrightwood, It's at the dead end with unimproved raw forest adjacent. This Hidden Gem has it All, Location, Seclusion, Privacy Awesome mountain Views. Many Wild Birds for the Bird Watcher. New breaker box installed with Edison compliance, new flooring, new plumbing in the master bathroom. It's 3bdr 2 bath home with wood burning Stove in family room and stone fireplace in formal front room, custom step Down Bar with built in Pinball machine indoor Hot Tub room and outdoor gas firepit. Enclosed Dog Run and Nine different kind of Fruit Trees.",
                }} className="featuredList-edit-link">See More</Link>

                



                {/* </>: null} */}
            </section>
        </div>

        <div className='featuredlistings-container'>
            <section className="featuredList-listing">
                <div className="featuredList-mainImage">
                    <img src={listing1} alt="house" className="featuredList-mainImage-img" />
                </div>
                <div className="featuredList-value-price">$539800</div>
                <div className="featuredList-address">874 Snowbird Road Wrightwood, CA 92397</div>
                <div className="featuredList-brief-desc">
                    <div className="featuredList-value-bed">3 beds•</div>
                    <div className="featuredList-value-baths">2 baths•</div>
                    <div className="featuredList-value-sqfootage">1900 sq ft•</div>
                    <div className="featuredList-value-type">Single Family Residence</div>
                </div>
                <div className="featuredList-mls">MLS # 24062943</div>

                {/* {list.address ? <> */}
                <Link to={"/featured_listingsDetails?listingId=666b6e7ca680627585417623"} state={{
                    _id: "666b6e7ca680627585417623",
                    address: "6226 Conifer Drive Wrightwood, CA 92397",
                    beds: 3,
                    baths: 2,
                    type: "Single Family Residence",
                    mls: 24100836,
                    price: 478000,
                    sqft: 1144,
                    coverImg: listing3,
                    desc: "Mountain living at its finest! This turnkey home is combining an exciting lifestyle with full upgrades: the ease of a single story, rarely found in larger Wrightwood homes, the seclusion of an uncommon cul de sac location with the convenient open concept floor plan located on an easy to access level lot. • Move-in ready with many upgrades • Large great room with cathedral – beamed ceiling • Stunning mountain views, 8 minutes from snow boarding and skiing at Mountain High, 10 minute walk to village • 3 bedrooms, 2 bathroom with one door into master bedroom • New windows added for more light • 1700 square feet • Classic brick wood-burning fireplace with gas starter • Roomy granite kitchen counters • Stainless steel sink, fridge, microwave and a gas stove with convection oven, dishwasher • Soft shut wood cabinets, slide out pantry trays and lazy susan • New high quality laminate flooring through out • New carpeting in bedrooms • Spacious en-suite bedroom • Over-sized primary bathroom with dual sinks and counter space to spare with custom walk-in shower • New light fixtures • New white paint • Updates to electrical system • New septic system • New garage door • Over-sized, attached 2-car garage with workshop area, RV parking • New cement driveway • Dual-pane insulated windows throughout • Tubular sky lighting • Fully fenced, triple-sized backyard • Trees trimmed and healthy • Steel roof, expensive and extra durable • Upgraded bathroom counters and cabinets • Outside 16’X8’ shed • Great hardscape in bloom • Fenced yard with gate county land • snowblower • Furniture: CA King size bed and furniture and snow blower, antique entry mirrored piece, swivel outdoor lounge chairs, glass top table, wood 7 seat kitchen table with insert, 2 lazy boy recliners and fold out couch.",
                }} className="featuredList-edit-link">See More</Link>

                



                {/* </>: null} */}
            </section>
        </div>




            {/* { currentListingItems && currentListingItems.length > 0 ?
            currentListingItems.map(listing=>(
                <FeaturedList key={ listing._id } listing={listing} />
            )):null} */}
            {/* {user ?
            <HomesForSaleForm setCurrentListingItems={setCurrentListingItems} setNewListAdded={setNewListAdded} />
            : null} */}
        </div>
        <MenuOverlay />
        </>
    );
}